import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Checkbox,
  Tooltip,
  TextField,
  ClickAwayListener,
  InputAdornment,
} from '@material-ui/core';
import styles from './PhotoCard.module.scss';
import { ActiveStarIcon, DownloadIcon, DeleteIcon, StarIcon, CheckedIcon } from 'icons';

interface PhotoCardProps {
  imageUrl?: string;
  caption?: string;
  useForReport?: boolean;
  isFavorite?: boolean;
  onToggleReport: () => void;
  onToggleFavorite: () => void;
  onDelete: () => void;
  onDownload: () => void;
  onClick: () => void;
  onCaptionChange: (caption?: string) => void;
}

export const PhotoCard: React.FC<PhotoCardProps> = ({
  imageUrl,
  caption,
  useForReport,
  isFavorite,
  onToggleReport,
  onToggleFavorite,
  onDelete,
  onDownload,
  onClick,
  onCaptionChange,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [tempCaption, setTempCaption] = useState(caption);

  const handleCaptionSave = () => {
    if (tempCaption !== caption) {
      onCaptionChange(tempCaption);
    }

    setEditMode(false);
  };

  const cancelEdit = () => {
    setTempCaption(caption);
    setEditMode(false);
  };

  return (
    <Card className={styles.card}>
      <CardMedia
        title="Photo preview"
        className={styles.media}
        image={`${process.env.REACT_APP__FILE_API_URL}${imageUrl}`}
        onClick={onClick}
      />

      <CardContent className={styles.content}>
        {editMode ? (
          <ClickAwayListener onClickAway={cancelEdit}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={tempCaption}
              onChange={(e) => setTempCaption(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleCaptionSave();
                if (e.key === 'Escape') cancelEdit();
              }}
              autoFocus
              label="Caption"
              className={clsx(styles.cardInput)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div className={styles.checkedContainer} onClick={handleCaptionSave}>
                      <CheckedIcon className={styles.checked} height={24} width={24} />
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </ClickAwayListener>
        ) : (
          <>
            {caption ? (
              <>
                <Typography variant="body2" className={styles.caption}>
                  {caption}
                </Typography>
                <Typography
                  variant="body2"
                  className={clsx(styles.editCaption, 'c-pointer text-primary')}
                  onClick={() => setEditMode(true)}
                >
                  Edit Caption
                </Typography>
              </>
            ) : (
              <Typography
                variant="body2"
                className={clsx(styles.addCaption, 'c-pointer text-primary')}
                onClick={() => setEditMode(true)}
              >
                Add Caption
              </Typography>
            )}
          </>
        )}
      </CardContent>

      <CardActions className={styles.actions}>
        <div className={styles.leftActions}>
          <Checkbox
            checked={useForReport}
            color="primary"
            onChange={onToggleReport}
            className={clsx(styles.checkboxRoot)}
          />
          <Typography variant="body2" className={clsx(styles.reportText)}>
            Use for the report
          </Typography>
        </div>
        <div className={styles.rightActions}>
          <Tooltip title="Favourite">
            <IconButton onClick={onToggleFavorite} className={clsx(styles.iconPadding)}>
              {isFavorite ? (
                <ActiveStarIcon className={clsx(styles.favIcon)} />
              ) : (
                <StarIcon className={clsx(styles.favIcon)} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Download">
            <IconButton onClick={onDownload} className={clsx(styles.iconPadding)}>
              <DownloadIcon className={clsx(styles.downloadIcon)} color="currentColor" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={onDelete} className={clsx(styles.iconPadding)}>
              <DeleteIcon className={clsx(styles.deleteIcon)} color="currentColor" />
            </IconButton>
          </Tooltip>
        </div>
      </CardActions>
    </Card>
  );
};
