import React, { DragEvent, FC, lazy, Suspense, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import imageCompression from 'browser-image-compression';

import { DeleteIcon, UploadFile } from 'icons';
import { apiRoutes } from 'api/routes';
import api from 'api/api';
import { PhotoCard, PhotoViewerDialog } from './components';
import { EmptyState } from '../Table';
import { components } from 'generated/types';
import { HttpErrorResponse } from 'types';

import styles from './PhotoGallery.module.scss';
import { CustomDialog } from '../Dialog';
const PhotoTable = lazy(() => import('./components/PhotoTable'));

const ConfirmationModal = lazy(() => import('components/common/ConfirmationModal'));

type InspectionPhoto = components['schemas']['InspectionPhoto'];
type FileInterface = components['schemas']['File'];

interface ConnectUrl {
  name: string;
  path: string;
  mimeType: string;
  documentCategories?: string[];
  trackingDocument?: boolean;
  projectId?: string;
}

type PhotoCardsProps = {
  projectId?: string;
  modal?: boolean;
  pathUpload: string;
  id?: string | number;
  filteredFiles?: InspectionPhoto[];
  filteredFilesFetching?: boolean;
  refetch: () => void;
  searchQuery?: string | undefined;
  handleClear?: () => void;
  inspectionId?: string;
  isTableView?: boolean;
};

export const PhotoGallery: FC<PhotoCardsProps> = ({
  projectId,
  pathUpload,
  id,
  filteredFiles,
  refetch,
  handleClear,
  inspectionId,
  isTableView,
}) => {
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<number | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [localFilteredFiles, setLocalFilteredFiles] = useState<InspectionPhoto[]>(filteredFiles || []);
  const [openViewer, setOpenViewer] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleOpenViewer = (index: number) => {
    setCurrentIndex(index);
    setOpenViewer(true);
  };

  const handleCloseViewer = () => setOpenViewer(false);

  const photoUploadUrlQuery = (name: string) =>
    api.get(`${apiRoutes.documentUploadUrl}?filename=${name}`).then((res) => res.data);

  const { mutateAsync: getFileUrl } = useMutation('photoUploadUrlQuery', (name: string) => photoUploadUrlQuery(name));

  const photosConnectUrlQuery = (fileData: ConnectUrl) =>
    api.post(`${apiRoutes.inspectionPhotos}/${inspectionId}/photos`, fileData).then((res) => res.data);

  const { mutateAsync: connectUrl } = useMutation('photosConnectUrlQuery', (fileData: ConnectUrl) =>
    photosConnectUrlQuery(fileData),
  );

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDrop = function (e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const files = e.dataTransfer.files;
    handleChange(files);
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleClickChange = function (e: React.ChangeEvent) {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    handleChange(files);
  };

  const handleChange = async (files: FileList | null) => {
    const dragDropArea = document.querySelector('.drag-drop-area') as HTMLElement;

    if (files) {
      const fileToArray: File[] = Object.values(files);

      try {
        for (let i = 0; i < fileToArray.length; i++) {
          const originalFile = fileToArray[i];
          const compressedFile = await imageCompression(originalFile, {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          });
          const { path, url } = await getFileUrl(compressedFile.name as string);

          const data = await axios.request({
            method: 'put',
            url: url,
            data: compressedFile,
            onUploadProgress: ({ loaded, total }) => {
              const fileLoaded = Math.floor((loaded / total) * 100);
              dragDropArea.innerHTML = `
                <div>
                  <li class=${styles.row}>
                    <div class=${styles.textWrapper}>
                      <p className="name">${fileLoaded}% ${i} files from ${fileToArray.length} uploaded...</p>
                    </div>
                  </li>
                </div>
              `;
            },
          });

          if (data.status === 200) {
            await connectUrl({
              name: fileToArray[i].name as string,
              path,
              mimeType: fileToArray[i].type as string,
              projectId: projectId,
              trackingDocument: false,
              [pathUpload]: id,
            });
          }
        }
        refetch();
        toast.success('File has been successfully saved!');
      } catch (e) {
        toast.error('Something go wrong, please try again');
      }
    }
  };

  const handleDownloadFile = async (file: FileInterface) => {
    try {
      setIsLoading(true);
      const response = await api.get(`${apiRoutes.files}/${file.uuid}`, { responseType: 'blob' });
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      a.href = url;

      if (file.name) {
        a.download = file.name;
      }

      document.body.append(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw new Error((e as HttpErrorResponse).message);
    }
  };

  const handleOpenDeleteDialog = (id: number) => {
    setFileToDelete(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setFileToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleDelete = () => deleteFileMutate(fileToDelete as number);

  const deleteFileMutation = (id: number) =>
    api.delete(`${apiRoutes.inspectionPhotos}/photos/${id}`).then((res) => res);

  const { mutate: deleteFileMutate } = useMutation('deleteFile', deleteFileMutation, {
    onSuccess: () => {
      toast.success('File successfully deleted!');
      refetch();
    },
    onError: () => {
      toast.error('Error deleting the file.');
    },
  });

  const toggleFavoriteMutation = (id: number) =>
    api.put(`${apiRoutes.inspectionPhotos}/photos/${id}/favorite`).then((res) => res);

  const { mutate: toggleFavoriteMutate } = useMutation('toggleFavorite', toggleFavoriteMutation, {
    onSuccess: () => {
      toast.success('Favorite status updated!');
    },
    onError: () => {
      toast.error('Error updating favorite status.');
    },
  });

  const toggleForReportMutation = (id: number) =>
    api.put(`${apiRoutes.inspectionPhotos}/photos/${id}/for-report`).then((res) => res);

  const { mutate: toggleForReportMutate } = useMutation('toggleForReport', toggleForReportMutation, {
    onSuccess: () => {
      toast.success('For report status updated!');
    },
    onError: () => {
      toast.error('Error updating for report status.');
    },
  });

  const editCaptionMutation = (id: number, value?: string) =>
    api.put(`${apiRoutes.inspectionPhotos}/photos/${id}/caption`, { caption: value ?? null }).then((res) => res);

  const { mutateAsync: editCaptionMutate } = useMutation(
    'editCaption',
    ({ id, value }: { id: number; value?: string }) => editCaptionMutation(id, value),
    {
      onSuccess: () => {
        toast.success('Caption updated!');
      },
      onError: () => {
        toast.error('Error updating caption.');
      },
    },
  );

  const handleRotateImage = async (id: number) => {
    try {
      const response = await api.put(`${apiRoutes.inspectionPhotos}/photos/${id}/rotate`);
      if (response.data) {
        const updatedImage = response.data;

        setLocalFilteredFiles((prev) => prev.map((file) => (file.id === id ? updatedImage : file)));

        toast.success('Image rotated successfully');
      }
    } catch (error) {
      toast.error('An error occurred while rotating the image');
    }
  };

  useEffect(() => {
    if (filteredFiles) {
      setLocalFilteredFiles(filteredFiles);
    }
  }, [filteredFiles]);

  const handleCaptionChange = (id: number, value: string) => {
    setLocalFilteredFiles((prev) => prev.map((file) => (file.id === id ? { ...file, caption: value } : file)));
    editCaptionMutate({ id, value });
  };

  const handleToggleReport = (id: number) => {
    setLocalFilteredFiles((prev) =>
      prev.map((file) => (file.id === id ? { ...file, isSelectedForReport: !file.isSelectedForReport } : file)),
    );
    toggleForReportMutate(id);
  };

  const handleToggleFavorite = (id: number) => {
    setLocalFilteredFiles((prev) =>
      prev.map((file) => (file.id === id ? { ...file, favorite: !file.favorite } : file)),
    );
    toggleFavoriteMutate(id);
  };

  return (
    <>
      {isLoading && (
        <div className={styles.progressBar}>
          <LinearProgress />
        </div>
      )}
      <div className={styles.wrapperMain}>
        <div className={dragActive ? styles.activeWrapper : styles.wrapper} onClick={(e) => e.stopPropagation()}>
          <div className={styles.formWrapper}>
            <form onDragEnter={handleDrag} onDragLeave={handleDragLeave} onDragOver={handleDrag} onDrop={handleDrop}>
              <label className={clsx('drag-drop-area', styles.dragDropArea)} htmlFor="file-upload">
                <UploadFile className={styles.img} />
                <p className={styles.greyText}>Drag & drop files here or</p>
                <p className={styles.textBlue}>Browse</p>
              </label>
              <input type="file" id="file-upload" className="file-input" multiple onChange={handleClickChange} />
            </form>
          </div>
        </div>

        <div>
          {!isTableView && (
            <>
              <div className={clsx(styles.cardWrapper, 'flex', 'flex-wrap', 'mt-24')}>
                {localFilteredFiles?.map((doc: InspectionPhoto, i: number) => (
                  <PhotoCard
                    key={doc.id}
                    imageUrl={doc.file?.thumbPath}
                    caption={doc.caption}
                    useForReport={doc.isSelectedForReport}
                    isFavorite={doc.favorite}
                    onToggleReport={() => handleToggleReport(doc.id as number)}
                    onToggleFavorite={() => handleToggleFavorite(doc.id as number)}
                    onDelete={() => handleOpenDeleteDialog(doc.id as number)}
                    onDownload={() => handleDownloadFile(doc.file as File)}
                    onCaptionChange={(value) => handleCaptionChange(doc.id as number, value as string)}
                    onClick={() => handleOpenViewer(i)}
                  />
                ))}
                {!localFilteredFiles?.length && <EmptyState model="files" onClick={handleClear} />}
              </div>
            </>
          )}

          {isTableView && (
            <Suspense fallback={<div>Loading...</div>}>
              {localFilteredFiles?.length ? (
                <PhotoTable
                  photos={localFilteredFiles as InspectionPhoto[]}
                  onToggleFavorite={handleToggleFavorite}
                  onToggleReport={handleToggleReport}
                  onDownload={(file) => handleDownloadFile(file)}
                  onDelete={(id) => handleOpenDeleteDialog(id)}
                  onClick={(index) => handleOpenViewer(index)}
                />
              ) : (
                <EmptyState model="files" onClick={handleClear} />
              )}
            </Suspense>
          )}
        </div>
      </div>

      <PhotoViewerDialog
        openViewer={openViewer}
        handleCloseViewer={handleCloseViewer}
        slides={localFilteredFiles as InspectionPhoto[]}
        onDelete={(id) => handleOpenDeleteDialog(id)}
        onCaptionChange={handleCaptionChange}
        onToggleReport={handleToggleReport}
        currentIndex={currentIndex}
        onRotateImage={handleRotateImage}
      />

      <CustomDialog
        open={openDeleteDialog}
        icon={<DeleteIcon />}
        header="Delete this file?"
        onClose={handleCloseDeleteDialog}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <ConfirmationModal
            text="You won’t be able to restore it"
            onClose={handleCloseDeleteDialog}
            onSubmit={handleDelete}
          />
        </Suspense>
      </CustomDialog>
    </>
  );
};
