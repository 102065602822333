import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  Tooltip,
  Collapse,
  Box,
  useMediaQuery,
} from '@material-ui/core';
import { ActiveStarIcon, DeleteIcon, DownloadIcon, StarIcon } from 'icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { components } from 'generated/types';
import styles from './PhotoTable.module.scss';

const useDynamicStyles = makeStyles((theme) => ({
  actionButton: {
    padding: theme.spacing(1),
    color: theme.palette.action.active,
    '& svg': {
      width: 24,
      height: 24,
    },
  },
}));

type InspectionPhoto = components['schemas']['InspectionPhoto'];

type PhotoTableProps = {
  photos: InspectionPhoto[];
  onToggleFavorite: (id: number) => void;
  onToggleReport: (id: number) => void;
  onDownload: (file: { thumbPath?: string; name?: string }) => void;
  onDelete: (id: number) => void;
  onClick: (id: number) => void;
};

export const PhotoTable: React.FC<PhotoTableProps> = ({
  photos,
  onToggleFavorite,
  onToggleReport,
  onDownload,
  onDelete,
  onClick,
}) => {
  const dynamicClasses = useDynamicStyles();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const toggleExpandRow = (id: number) => {
    setExpandedRows((prev) => (prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]));
  };

  return (
    <>
      {!isMobile && (
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell>Photo</TableCell>
              <TableCell>Caption</TableCell>
              <TableCell align="center">Use for Report</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {photos.map((photo) => (
              <TableRow key={photo.id}>
                <TableCell>
                  <div className={styles.thumbnailContainer}>
                    <img
                      src={`${process.env.REACT_APP__FILE_API_URL}${photo.file?.thumbPath}`}
                      alt={photo.file?.name}
                      className={styles.thumbnail}
                      onClick={() => onClick(photo.id as number)}
                    />
                    <span className={styles.fileName}>{photo.file?.name}</span>
                  </div>
                </TableCell>
                <TableCell>
                  {photo.caption ? (
                    <span className={styles.captionText}>{photo.caption}</span>
                  ) : (
                    <span className={styles.noCaptionText}>No caption</span>
                  )}
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={photo.isSelectedForReport}
                    color="primary"
                    onChange={() => onToggleReport(photo.id as number)}
                  />
                </TableCell>
                <TableCell align="center">
                  <div className={styles.actionsContainer}>
                    <Tooltip title="Favourite">
                      <IconButton
                        className={dynamicClasses.actionButton}
                        onClick={() => onToggleFavorite(photo.id as number)}
                      >
                        {photo.favorite ? <ActiveStarIcon /> : <StarIcon />}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Download">
                      <IconButton
                        className={dynamicClasses.actionButton}
                        onClick={() => onDownload(photo.file as File)}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton className={dynamicClasses.actionButton} onClick={() => onDelete(photo.id as number)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {isMobile && (
        <div className={styles.mobileContainer}>
          {photos.map((photo) => (
            <div key={photo.id} className={styles.row}>
              <div className={styles.mobileHeader}>
                <div className={styles.mobileThumbnailContainer}>
                  <img
                    src={`${process.env.REACT_APP__FILE_API_URL}${photo.file?.thumbPath}`}
                    alt={photo.file?.name}
                    className={styles.thumbnail}
                    onClick={() => onClick(photo.id as number)}
                  />
                  <span className={styles.fileName}>{photo.file?.name}</span>
                </div>
                <IconButton
                  className={`${styles.expandButton} ${
                    expandedRows.includes(photo.id as number) ? styles.expanded : ''
                  }`}
                  onClick={() => toggleExpandRow(photo.id as number)}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </div>
              <Collapse in={expandedRows.includes(photo.id as number)} timeout="auto" unmountOnExit>
                <Box className={styles.detailRow}>
                  <div className="mt-8 mb-8">
                    <strong>Caption:</strong>{' '}
                    {photo.caption || <span className={styles.noCaptionText}>No caption</span>}
                  </div>
                  <div>
                    <strong>Use for Report:</strong>{' '}
                    <Checkbox
                      checked={photo.isSelectedForReport}
                      color="primary"
                      onChange={() => onToggleReport(photo.id as number)}
                    />
                  </div>
                  <div className={styles.actionsContainer}>
                    <Tooltip title="Favourite">
                      <IconButton
                        className={dynamicClasses.actionButton}
                        onClick={() => onToggleFavorite(photo.id as number)}
                      >
                        {photo.favorite ? <ActiveStarIcon /> : <StarIcon />}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Download">
                      <IconButton
                        className={dynamicClasses.actionButton}
                        onClick={() => onDownload(photo.file as File)}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton className={dynamicClasses.actionButton} onClick={() => onDelete(photo.id as number)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Box>
              </Collapse>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
