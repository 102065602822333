import React, { useState, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs, Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import clsx from 'clsx';
import { Typography, IconButton, Checkbox, TextField, InputAdornment } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import { CheckedIcon, DeleteIcon } from 'icons';
import styles from './PhotoViewer.module.scss';
import { components } from 'generated/types';
import { apiRoutes } from 'api/routes';

type InspectionPhoto = components['schemas']['InspectionPhoto'];

interface PhotoViewerProps {
  slides: InspectionPhoto[];
  onDelete: (id: number) => void;
  onCaptionChange: (id: number, value: string) => void;
  onToggleReport: (id: number) => void;
  currentIndex: number;
  onRotateImage: (id: number) => void;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  button: {
    background: 'rgba(255, 255, 255, 0.9)',
    marginLeft: '5px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 1)',
    },
  },
}));

export const PhotoViewer = ({
  slides,
  onDelete,
  onCaptionChange,
  onToggleReport,
  currentIndex,
  onRotateImage,
  onClose,
}: PhotoViewerProps) => {
  const classes = useStyles();
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [tempCaption, setTempCaption] = useState<string>('');
  const [imageTimestamps, setImageTimestamps] = useState<Record<number, string>>({});
  const swiperRef = useRef<any>(null);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const handleSaveCaption = () => {
    const activeSlide = slides[activeSlideIndex];

    if (activeSlide) {
      onCaptionChange(activeSlide.id as number, tempCaption);

      if (swiperRef.current && activeSlideIndex < slides.length - 1) {
        swiperRef.current.slideNext();
      }
    }
  };

  const handleSlideChange = (swiper: any) => {
    const newIndex = swiper.activeIndex;
    setActiveSlideIndex(newIndex);

    if (thumbsSwiper) {
      thumbsSwiper.slideTo(newIndex, 300);
    }

    const newSlide = slides[newIndex];
    setTempCaption(newSlide?.caption || '');
  };

  const activeSlide = slides[activeSlideIndex];

  useEffect(() => {
    if (slides[activeSlideIndex]?.caption) {
      setTempCaption(slides[activeSlideIndex].caption as string);
    }
  }, [activeSlideIndex, slides]);

  const handleRotate = async (id: number) => {
    await onRotateImage(id);

    setImageTimestamps((prev) => ({
      ...prev,
      [id]: `?timestamp=${new Date().getTime()}`,
    }));
  };

  return (
    <>
      <div className={clsx(styles.topActions)}>
        <span className={clsx(styles.headerText)}>Preview</span>
        <div className={clsx(styles.headerActions)}>
          <IconButton className={classes.button} onClick={() => handleRotate(slides[activeSlideIndex].id as number)}>
            <RotateLeftIcon />
          </IconButton>
          <span className={styles.iconText}>Rotate</span>
          <IconButton className={classes.button} onClick={() => onDelete(activeSlide.id as number)}>
            <DeleteIcon className={clsx(styles.deleteIcon)} color="currentColor" />
          </IconButton>
          <IconButton onClick={onClose} className={clsx(styles.closeButton)} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <span className={styles.iconText}>Delete</span>
        </div>
      </div>

      {/* Main slider */}
      <div className={styles.container}>
        <Swiper
          modules={[Thumbs, Navigation]}
          thumbs={{
            swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          spaceBetween={16}
          slidesPerView={1}
          initialSlide={currentIndex}
          onSlideChange={handleSlideChange}
          navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
            // @ts-ignore
            swiper.params.navigation.prevEl = prevRef.current;
            // @ts-ignore
            swiper.params.navigation.nextEl = nextRef.current;
          }}
          className={styles.mainSwiper}
        >
          {slides.map((slide) => (
            <SwiperSlide key={slide.id}>
              <img
                loading="lazy"
                src={`${process.env.REACT_APP_API_URL}${apiRoutes.files}/${slide.file?.uuid}${
                  imageTimestamps[slide.id as number] || ''
                }`}
                alt={slide.file?.name || 'Photo'}
                className={styles.mainImage}
              />
              <div className="swiper-lazy-preloader"></div>
            </SwiperSlide>
          ))}
        </Swiper>

        <IconButton ref={prevRef} className={clsx(styles.navButton, styles.prevButton)}>
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton ref={nextRef} className={clsx(styles.navButton, styles.nextButton)}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>

      <div className={styles.bottomActions}>
        {/* Caption */}
        <div className={styles.captionWrapper}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={tempCaption}
            onChange={(e) => setTempCaption(e.target.value)}
            label="Caption"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div className={styles.checkedContainer} onClick={handleSaveCaption}>
                    <CheckedIcon className={styles.checked} height={24} width={24} />
                  </div>
                </InputAdornment>
              ),
            }}
          />
        </div>

        {/* Use for the Report */}
        <div className={styles.useForReport}>
          <Checkbox
            color="primary"
            checked={activeSlide?.isSelectedForReport || false}
            onChange={() => onToggleReport(activeSlide.id as number)}
            className={styles.checkboxRoot}
          />
          <Typography variant="body2" className={styles.reportText}>
            Use for the report
          </Typography>
        </div>
      </div>

      {/* Miniature slider */}
      <div className={styles.thumb}>
        <Swiper
          modules={[Thumbs]}
          onSwiper={setThumbsSwiper}
          centeredSlides
          watchSlidesProgress
          spaceBetween={8}
          slidesPerView={6}
          className={clsx(styles.customSwiper)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={slide.id} className={clsx(styles.customSlide)}>
              <img
                src={`${process.env.REACT_APP__FILE_API_URL}${slide.file?.thumbPath}` || ''}
                alt={slide.file?.name || 'Thumbnail'}
                className={clsx(activeSlideIndex === index && styles.activeThumb, styles.thumbImage)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};
