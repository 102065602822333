import React, { ChangeEvent, FC, lazy, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import {
  Button,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { UseMutateAsyncFunction, useMutation, useQuery } from 'react-query';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { components } from 'generated/types';
import { ArrowDownIcon, ChangeCategoryIcon, CheckedIcon, CloseIcon, DeleteIcon, DownloadIcon, EditIcon } from 'icons';
import { CustomDialog } from 'components';
import { onDragOver } from 'services';
import { api, apiRoutes } from 'api';
import { noImage } from 'assets/images';
import useLongPress from 'utils/useLongPress';
import { Iterator } from 'utils';
import { AxiosResponse } from 'axios';
import { ReportTypesEnum } from 'types';

const ConfirmationModal = lazy(() => import('components/common/ConfirmationModal'));

import styles from './PhotoDrawer.module.scss';

type ReportFileSection = components['schemas']['ReportFileSectionResponse'];
interface ReportFile {
  id?: number | string;
  caption?: string;
  position?: number;
  file?: components['schemas']['File'];
  type?: string;
  /** Format: date-time */
  createdAt?: string;
  /** Format: date-time */
  updatedAt?: string;
}

type FileProps = {
  id: number | string;
  name?: string;
  src?: string;
  caption?: string;
  viewFile: () => void;
  deleteFile: () => void;
  editFile: (value: string) => void;
  onDragStart: () => void;
  onDragEnter: () => void;
  onDragOver: (evt: React.DragEvent<HTMLDivElement>) => void;
  onDrop: () => Promise<void>;
  handleChangeMobilePosition: (direction: 'up' | 'down') => void;
  index: number;
  isLast: boolean;
  type: string;
  handleChangeCategory: (type: string) => void;
  handleSelectFile: (id: number | string) => void;
  selectedFiles: Array<string | number>;
  filteredTypes: ReportFileSection[];
  category?: string;
};

type PhotoDrawerProps = {
  data: ReportFile[];
  reportId: number;
  handleClose: () => void;
  reportType: string | undefined;
};

//eslint-disable-next-line
const useOutsideAlerter = (ref: MutableRefObject<any>, callback: () => void, v?: any) => {
  useEffect(() => {
    //eslint-disable-next-line
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        console.log('You clicked outside of me!');
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, v]);
};

function is_cached(src: string) {
  const image = new Image();
  image.src = src;

  return image.complete;
}

const FilePreview = ({
  documents,
  initialActiveDocument,
  updateFile,
  editFileLoading,
  isNewImage,
}: {
  documents: ReportFile[];
  initialActiveDocument: React.MutableRefObject<number>;
  className: string;
  updateFile: ({ id, value }: { id: number | string; value: string }) => Promise<void>;
  editFileLoading: boolean;
  isNewImage: boolean;
}) => {
  const wrapperRef = useRef<HTMLInputElement | null>(null);
  const [activeDocument, setActiveDocument] = useState(documents[initialActiveDocument.current]);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState<string>(activeDocument.caption ?? '');
  const [isEditMode, setIsEditMode] = useState(false);

  const cancelCaptionSave = () => {
    setValue(activeDocument.caption ?? '');
    setIsEditMode(false);
  };

  const saveCaption = () => {
    if (wrapperRef.current) {
      updateFile({ value: wrapperRef.current.value, id: activeDocument.id as number | string });
    }
    setIsEditMode(false);
  };

  const setEditMode = () => setIsEditMode(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setValue(activeDocument.caption ?? '');
  }, [activeDocument]);

  useEffect(() => {
    !is_cached(
      `${process.env.REACT_APP_API_URL}${apiRoutes.files}/${documents[initialActiveDocument.current].file?.uuid}`,
    ) && setIsLoading(true);
    setActiveDocument(documents[initialActiveDocument.current]);
  }, [initialActiveDocument, documents, isNewImage]);

  const documentsIterator = new Iterator(
    documents,
    documents.findIndex(({ id }) => id === activeDocument.id),
  );

  useEffect(() => {
    if (isEditMode && wrapperRef.current) {
      wrapperRef.current.focus();
      wrapperRef.current.setSelectionRange(value.length, value.length);
    }
  }, [isEditMode]);

  if (editFileLoading) return <CircularProgress size={64} />;

  return (
    <>
      {(isLoading || editFileLoading) && <CircularProgress size={64} />}
      <div className={clsx((isLoading || editFileLoading) && 'd-none', 'px-16')}>
        <h2 className="mb-12 pr-64  pl-sm-0">{activeDocument.file?.name}</h2>
        <div className="flex justify-content-between my-16">
          <Button
            variant="contained"
            className="mb-12 ml-sm-0"
            onClick={() => {
              documentsIterator.prev();
              setActiveDocument(documentsIterator.current());
              setIsLoading(true);
            }}
            disabled={!documentsIterator.hasPrev()}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            className="mb-12  mr-sm-0"
            onClick={() => {
              documentsIterator.next();
              setActiveDocument(documentsIterator.current());
              setIsLoading(true);
            }}
            disabled={!documentsIterator.hasNext()}
          >
            Next
          </Button>
        </div>
        <div className="text-center">
          <img
            src={`${process.env.REACT_APP_API_URL}${apiRoutes.files}/${activeDocument.file?.uuid}`}
            alt={activeDocument.caption}
            onLoad={handleImageLoad}
            className={styles.imageBlock}
          />
        </div>

        <p className="mt-16 text-center flex align-items-center justify-content-center">
          {isEditMode ? (
            <ClickAwayListener onClickAway={cancelCaptionSave}>
              <TextField
                multiline
                autoFocus
                label="Caption"
                inputRef={wrapperRef}
                value={value}
                className={clsx(styles.textarea)}
                onChange={(e) => setValue(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <div className={styles.checkedContainer} onClick={saveCaption}>
                      <CheckedIcon className={styles.checked} />
                    </div>
                  ),
                }}
              />
            </ClickAwayListener>
          ) : (
            <>
              {value.length ? (
                <p onClick={setEditMode} className="c-pointer">
                  {value}
                </p>
              ) : (
                <p onClick={setEditMode} className="text-primary c-pointer">
                  Add caption
                </p>
              )}
            </>
          )}
        </p>
      </div>
    </>
  );
};

const File: FC<FileProps> = ({
  id,
  name,
  src,
  type,
  caption,
  viewFile,
  deleteFile,
  onDragEnter,
  onDragOver,
  onDragStart,
  editFile,
  onDrop,
  handleChangeMobilePosition,
  index,
  isLast,
  handleChangeCategory,
  handleSelectFile,
  selectedFiles,
  filteredTypes,
}) => {
  const wrapperRef = useRef<HTMLInputElement | null>(null);
  const wrapperRefMobile = useRef<HTMLInputElement | null>(null);

  const [value, setValue] = useState<string>(caption ?? '');

  const [isEditMode, setIsEditMode] = useState(false);
  const [isMobileEditMode, setIsMobileEditMode] = useState(false);
  const [fileConfirmationModal, setFileConfirmationModal] = useState(false);
  const [openCategoryChange, setOpenCategoryChange] = useState(false);
  const [categoryToMove, setCategoryToMove] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onLongPress = () => handleSelectFile(id);
  const onClick = () => console.log('click is triggered');
  const defaultOptions = {
    shouldPreventDefault: false,
    delay: 500,
  };

  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  //eslint-disable-next-line
  const handleClick = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleCloseCategoryChange = () => setOpenCategoryChange(false);
  const handleOpenCategoryChange = () => setOpenCategoryChange(true);

  const handleFileCategoryChange = () => handleChangeCategory(categoryToMove);

  const handleCategoryChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setCategoryToMove(value);

  const handleConfirmationModalOpen = () => setFileConfirmationModal(true);
  const handleConfirmationModalClose = () => setFileConfirmationModal(false);

  const setEditMode = () => setIsEditMode(true);

  const saveCaption = () => {
    editFile(value);
    setIsEditMode(false);
  };

  const cancelCaptionSave = () => {
    setValue(caption ?? '');
    setIsEditMode(false);
  };

  const cancelMobileCaptionSave = () => {
    setValue(caption ?? '');
    setIsMobileEditMode(false);
  };

  useEffect(() => {
    setValue(caption ?? '');
  }, [caption]);

  useEffect(() => {
    if (isEditMode && wrapperRef.current) {
      wrapperRef.current.focus();
      wrapperRef.current.setSelectionRange(value.length, value.length);
    }
  }, [isEditMode]);

  useEffect(() => {
    if (isEditMode && wrapperRef.current) {
      wrapperRef.current.focus();
      wrapperRef.current.setSelectionRange(value.length, value.length);
    }
    if (isMobileEditMode && wrapperRefMobile.current) {
      wrapperRefMobile.current.focus();
      wrapperRefMobile.current.setSelectionRange(value.length, value.length);
    }
  }, [isMobileEditMode]);

  return (
    <>
      <div
        draggable
        onDrop={onDrop}
        onDragStart={onDragStart}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        className="align-items-start px-12 mb-12 py-12 pr-24 d-none d-sm-flex"
      >
        <div className={clsx(styles.imageContainer, 'p-relative')}>
          <FormGroup className="ml-4">
            <Checkbox
              className={styles.checkbox}
              checked={selectedFiles.some((idx) => idx === id)}
              onChange={() => handleSelectFile(id)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />

            <span className={styles.dragPointer}></span>
          </FormGroup>
          <img
            src={`${process.env.REACT_APP__FILE_API_URL}${src}`}
            alt={name}
            className={styles.fileThumb}
            onClick={viewFile}
          />
        </div>

        <div className={clsx(styles.fileInfo, 'ml-12 text-14 flex-1')}>
          <p className="flex justify-content-between weight-700  align-items-start mb-8">
            <span className={styles.imageName}>{name}</span>

            <MoreVertIcon onClick={handleClick} className="c-pointer" />

            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem
                onClick={() => {
                  handleOpenCategoryChange();
                  handleClose();
                }}
              >
                <ChangeCategoryIcon className="c-pointer mr-8" /> Move to different tab
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleConfirmationModalOpen();
                  handleClose();
                }}
              >
                <DeleteIcon className={clsx(styles.deleteIcon, 'mr-8')} />
                <span className={styles.deleteTitle}>Delete</span>
              </MenuItem>
            </Menu>
          </p>

          {isEditMode ? (
            <ClickAwayListener onClickAway={cancelCaptionSave}>
              <TextField
                multiline
                autoFocus
                inputRef={wrapperRef}
                label="Caption"
                value={value}
                className={clsx(styles.textarea, 'mt-8 mb-12')}
                onChange={(e) => setValue(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                InputProps={{
                  endAdornment: (
                    <div className={styles.checkedContainer} onClick={saveCaption}>
                      <CheckedIcon className={styles.checked} />
                    </div>
                  ),
                }}
              />
            </ClickAwayListener>
          ) : (
            <div className="mt-16 mb-12" style={{ minHeight: '59px' }}>
              <p className="mb-12 pr-32 c-pointer" onClick={setEditMode}>
                {caption}
              </p>
              {!caption?.length && (
                <span className="text-primary c-pointer" onClick={setEditMode}>
                  Add caption
                </span>
              )}
            </div>
          )}
        </div>
      </div>

      <div
        className={clsx(
          'align-items-start px-16 py-12 d-flex d-sm-none p-relative',
          selectedFiles.some((idx) => idx === id) && styles.mobileActive,
        )}
        {...longPressEvent}
      >
        {!!index && <ArrowDownIcon className={styles.arrowUp} onClick={() => handleChangeMobilePosition('up')} />}
        <div className="align-items-start d-flex ml-32 flex-1">
          <div className={clsx(styles.imageContainer, styles.mobileImageContainer)}>
            <img
              onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available?
                // e.stopImmediatePropagation();
                return false;
              }}
              src={`${process.env.REACT_APP__FILE_API_URL}${src}`}
              alt={name}
              className={styles.fileThumb}
              onClick={viewFile}
            />
          </div>

          <div className={clsx(styles.fileInfo, 'ml-12 text-14 flex-1')}>
            <p className="flex justify-content-between weight-700  align-items-start mb-8">
              <span className={styles.imageName}>{name}</span>
              <MoreVertIcon onClick={handleClick} className="c-pointer" />

              <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    handleOpenCategoryChange();
                    handleClose();
                  }}
                >
                  <ChangeCategoryIcon className="c-pointer mr-8" /> Move to different tab
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleConfirmationModalOpen();
                    handleClose();
                  }}
                >
                  <DeleteIcon className={clsx(styles.deleteIcon, 'mr-8')} />
                  <span className={styles.deleteTitle}>Delete</span>
                </MenuItem>
              </Menu>
            </p>

            {isMobileEditMode ? (
              <ClickAwayListener onClickAway={cancelMobileCaptionSave}>
                <TextField
                  autoFocus
                  inputRef={wrapperRefMobile}
                  multiline
                  label="Caption"
                  value={value}
                  className={clsx(styles.textarea, ' mt-8 mb-12')}
                  onChange={(e) => setValue(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  InputProps={{
                    endAdornment: (
                      <div className={styles.checkedContainer} onClick={saveCaption}>
                        <CheckedIcon className={styles.checked} />
                      </div>
                    ),
                  }}
                />
              </ClickAwayListener>
            ) : (
              <p className="mb-12 pr-32 c-pointer" onClick={() => setIsMobileEditMode(true)}>
                {caption}
              </p>
            )}
            {!isEditMode && !caption?.length && (
              <span className="text-primary c-pointer" onClick={() => setIsMobileEditMode(true)}>
                Add caption
              </span>
            )}
          </div>
        </div>

        {!isLast && <ArrowDownIcon className={styles.arrowDown} onClick={() => handleChangeMobilePosition('down')} />}
      </div>

      <CustomDialog
        open={fileConfirmationModal}
        icon={<DeleteIcon />}
        header="Delete this file?"
        onClose={handleConfirmationModalClose}
        isDelete
      >
        <ConfirmationModal
          isLoading={false} // TODO: replace with loader
          text="You won’t be able to restore it"
          onClose={handleConfirmationModalClose}
          onSubmit={deleteFile}
        />
      </CustomDialog>

      <CustomDialog open={openCategoryChange} className="custom-dialog" onClose={handleCloseCategoryChange}>
        <div className="flex flex-column p-16">
          <p className="weight-600 text-20">Select a tab where to move selected photo</p>
          <RadioGroup
            className="mt-16"
            aria-label="gender"
            name="gender1"
            value={categoryToMove}
            onChange={handleCategoryChange}
          >
            {filteredTypes?.map(({ type: typeId, title }) => (
              <FormControlLabel
                key={typeId}
                value={typeId}
                control={<Radio />}
                label={title}
                disabled={type === typeId}
              />
            ))}
          </RadioGroup>
          <div className="flex justify-content-center mt-24">
            <Button disabled={!categoryToMove} onClick={handleFileCategoryChange}>
              Move
            </Button>
          </div>
        </div>
      </CustomDialog>
    </>
  );
};

const DragDropFiles = ({
  files,
  reportId,
  getReportFiles,
  filteredTypes,
  setOpenFilePreview,
  startFile,
  editFileMutate,
  editFileLoading,
}: {
  files?: ReportFile[];
  reportId: number;
  getReportFiles: () => void;
  filteredTypes: ReportFileSection[];
  openFilePreview: boolean;
  setOpenFilePreview: (value: boolean) => void;
  startFile: React.MutableRefObject<number | string>;
  editFileMutate: UseMutateAsyncFunction<
    //eslint-disable-next-line
    AxiosResponse<any, any>,
    unknown,
    {
      id: number | string;
      value: string;
    },
    unknown
  >;
  editFileLoading: boolean;
}) => {
  const [dragItem, updateDragItem] = useState<number>(0);

  const [openCategoryChange, setOpenCategoryChange] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Array<string | number>>([]);
  const [categoryToMove, setCategoryToMove] = useState('');
  const [fileConfirmationModal, setFileConfirmationModal] = useState(false);

  const [filesList, setFilesList] = useState<ReportFile[]>(files ?? []);

  const updateFilePositionMutation = (value: Array<{ id: number | string; position: number }>) =>
    api.put(`${apiRoutes.reports}/${reportId}/files/positions`, { positionsData: value }).then((res) => res);

  const { mutateAsync: updateFilePositionMutate, isLoading: updateFilePositionLoading } = useMutation(
    'updateFilePositionMutation',
    (value: Array<{ id: number | string; position: number }>) => updateFilePositionMutation(value),
    {
      onSuccess: () => {
        toast.success('File position has been successfully updated!');
        getReportFiles();
      },
    },
  );

  const updateFileCategoryMutation = (value: { ids: Array<string | number>; type: string }) =>
    api.put(`${apiRoutes.reports}/${reportId}/files/type`, { ...value }).then((res) => res);

  const { mutateAsync: updateFileCategoryMutate, isLoading: updateFileCategoryLoading } = useMutation(
    'updateFilePositionMutation',
    (value: { ids: Array<string | number>; type: string }) => updateFileCategoryMutation(value),
    {
      onSuccess: () => {
        toast.success('File category has been successfully updated!');
        getReportFiles();
      },
    },
  );

  const deleteFileMutation = (ids: Array<string | number>) =>
    api
      .delete(`${apiRoutes.reports}/${reportId}/files/delete`, {
        data: {
          ids,
        },
      })
      .then((res) => res);

  const { mutate: deleteFileMutate, isLoading: deleteFileLoading } = useMutation(
    'deleteWorkOderMutation',
    (ids: Array<string | number>) => deleteFileMutation(ids),
    {
      onSuccess: () => {
        toast.success('File has been successfully deleted!');
        getReportFiles();
      },
    },
  );

  const updateMobileFilePositionMutation = (value: { id: number | string; up: boolean; down: boolean }) =>
    api.put(`${apiRoutes.reports}/${reportId}/files/position`, value).then((res) => res);

  const { mutateAsync: updateMobileFilePositionMutate, isLoading: updateMobileFilePositionLoading } = useMutation(
    'updateFilePositionMutation',
    (value: { id: number | string; up: boolean; down: boolean }) => updateMobileFilePositionMutation(value),
    {
      onSuccess: () => {
        toast.success('File position has been successfully updated!');
        getReportFiles();
      },
    },
  );

  const handleDragStart = (index: number) => {
    updateDragItem(index);
  };

  const handleDragEnter = (index: number) => {
    const newList = [...filesList];
    const item = newList[dragItem];
    newList.splice(dragItem, 1);
    newList.splice(index, 0, item);
    updateDragItem(index);
    setFilesList(newList);
  };

  const updateFilePosition = async () => {
    const newArr = filesList.map(({ id }, index) => ({ id: id as number | string, position: index }));
    await updateFilePositionMutate(newArr);
  };

  const handleOpenFile = (fileIndex: number | string) => {
    startFile.current = fileIndex;
    setOpenFilePreview(true);
  };

  const handleChangePosition = (id: number | string, direction: 'down' | 'up') => {
    updateMobileFilePositionMutate({ id, up: direction === 'up', down: direction === 'down' });
  };

  useEffect(() => {
    if (files?.length) {
      setFilesList(files);
      return;
    }
    setFilesList([]);
  }, [files]);

  const handleSelectFile = (idx: number | string) => {
    selectedFiles.some((id) => id === idx)
      ? setSelectedFiles(selectedFiles.filter((id) => id !== idx))
      : setSelectedFiles([...selectedFiles, idx]);
  };

  const setAllSelectedFiles = () => {
    const filesByTab = filesList;
    if (filesByTab?.length) {
      selectedFiles.length === filesByTab.length
        ? setSelectedFiles([])
        : setSelectedFiles([...(filesByTab.map(({ id }) => id) as Array<string | number>)]);
    }
  };

  const handleConfirmationModalOpen = () => setFileConfirmationModal(true);
  const handleConfirmationModalClose = () => setFileConfirmationModal(false);

  const handleDeleteSelectedFiles = () => {
    handleConfirmationModalOpen();
  };

  const handleCategoryChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setCategoryToMove(value);
  };

  const handleSelectedFilesCategoryChange = () =>
    updateFileCategoryMutate({ ids: selectedFiles, type: categoryToMove });

  const handleChangeCategory = (id: number, type: string) => updateFileCategoryMutate({ ids: [id], type });

  const handleCloseCategoryChange = () => setOpenCategoryChange(false);

  const handleOpenCategoryChange = () => setOpenCategoryChange(true);

  const deleteSelectedFiles = () => {
    deleteFileMutate(selectedFiles);
  };

  if (
    updateFilePositionLoading ||
    deleteFileLoading ||
    updateMobileFilePositionLoading ||
    // editFileLoading ||
    updateFileCategoryLoading
  )
    return <CircularProgress size={64} />;

  return (
    <>
      {editFileLoading && (
        <div className={styles.loading}>
          <CircularProgress size={64} />
        </div>
      )}
      {!!filesList.length && (
        <>
          <FormGroup className={clsx('px-24', styles.selectAllContainer)}>
            <div className="flex align-items-center justify-content-between">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filesList.length === selectedFiles.length}
                    onChange={setAllSelectedFiles}
                    name="Select all"
                  />
                }
                label={`Select all (${selectedFiles.length})`}
              />
              <span>
                {!!selectedFiles.length && (
                  <>
                    <ChangeCategoryIcon className="c-pointer mr-8" onClick={handleOpenCategoryChange} />
                    <DeleteIcon className={styles.deleteIcon} onClick={handleDeleteSelectedFiles} />
                  </>
                )}
              </span>
            </div>
            <p className="d-none d-sm-flex text-secondary mb-8 text-14">Drag and drop to change the photo position</p>
            <p className="d-flex d-sm-none text-secondary mb-8 text-14">Long press on a photo to select one</p>
          </FormGroup>
          {filesList.map(({ id, file, caption, type }, index) => (
            <File
              id={id as number}
              index={index}
              type={type as string}
              isLast={index === filesList.length - 1}
              name={file?.name}
              src={file?.thumbPath}
              caption={caption}
              key={id}
              viewFile={() => handleOpenFile(index)}
              deleteFile={() => deleteFileMutate([id as number | string])}
              editFile={(value) => editFileMutate({ value, id: id as number | string })}
              onDragStart={() => handleDragStart(index)}
              onDragEnter={() => handleDragEnter(index)}
              onDragOver={onDragOver}
              onDrop={updateFilePosition}
              handleChangeMobilePosition={(direction) => handleChangePosition(id as number, direction)}
              handleChangeCategory={(type: string) => handleChangeCategory(id as number, type)}
              handleSelectFile={handleSelectFile}
              selectedFiles={selectedFiles}
              filteredTypes={filteredTypes}
            />
          ))}
        </>
      )}

      {!filesList.length && (
        <div className="p-relative w-100 h-100 mb-24">
          <div className={styles.emptyState}>
            <img alt="no-image" className="mt-32" src={noImage} />
            <p className="text-secondary">No images have been provided yet!</p>
          </div>
        </div>
      )}

      <CustomDialog open={openCategoryChange} className="custom-dialog" onClose={handleCloseCategoryChange}>
        <div className="flex flex-column p-16">
          <p className="weight-600 text-20">Select a tab where to move selected photos</p>
          <RadioGroup
            className="mt-16"
            aria-label="gender"
            name="gender1"
            value={categoryToMove}
            onChange={handleCategoryChange}
          >
            {filteredTypes?.map(({ type, title }) => (
              <FormControlLabel
                key={type}
                value={type}
                control={<Radio />}
                label={title}
                disabled={filesList[0]?.type === type || (type === 'cover' && selectedFiles.length > 1)}
              />
            ))}
          </RadioGroup>
          <div className="flex justify-content-center mt-24">
            <Button disabled={!categoryToMove} onClick={handleSelectedFilesCategoryChange}>
              Move
            </Button>
          </div>
        </div>
      </CustomDialog>

      <CustomDialog
        open={fileConfirmationModal}
        icon={<DeleteIcon />}
        header="Delete these files?"
        onClose={handleConfirmationModalClose}
      >
        <ConfirmationModal
          isLoading={false} // TODO: replace with loader
          text="You won’t be able to restore them"
          onClose={handleConfirmationModalClose}
          onSubmit={deleteSelectedFiles}
        />
      </CustomDialog>
    </>
  );
};

const AccordionItem = ({
  originalCaption,
  updateCategoryCaption,
  type,
}: {
  originalCaption?: string;
  updateCategoryCaption: (value: { type?: string; caption?: string }) => void;
  type?: string;
}) => {
  const [caption, setCaption] = useState(originalCaption);

  const saveCaption = () => {
    updateCategoryCaption({ caption, type });
  };

  return (
    <TextField
      value={caption}
      className={clsx(styles.subLabel, styles.textField, ' mt-8 mb-12')}
      onChange={(e) => setCaption(e.target.value)}
      InputProps={{
        endAdornment: (
          <div className={styles.checkedContainer} onClick={saveCaption}>
            <CheckedIcon className={styles.checked} />
          </div>
        ),
      }}
    />
  );
};

const PhotoDrawer: FC<PhotoDrawerProps> = ({ reportId, handleClose, reportType }) => {
  const startFile = useRef<number>(0);
  const [activeTab, setActiveTab] = useState<string>('');
  const [expandedSubTitle, setExpandedSubTitle] = React.useState<string | null>();
  const [openFilePreview, setOpenFilePreview] = useState(false);
  const [isNewImage, setIsNewImage] = useState(false);

  const reportFilesQuery = () =>
    api.get<ReportFile[]>(`${apiRoutes.reports}/${reportId}/files`, {}).then((res) => res.data);

  const {
    data: reportFiles,
    refetch: getReportFiles,
    isLoading,
  } = useQuery<ReportFile[]>(['reportFilesQuery', reportId], () => reportFilesQuery());

  const reportTypesQuery = () =>
    api.get<ReportFileSection[]>(`${apiRoutes.reports}/${reportId}/files/section`, {}).then((res) => res.data);

  const {
    data: reportTypes,
    refetch: getReportTypes,
    isLoading: reportTypesLoading,
  } = useQuery(['reportTypesQuery', reportId], () => reportTypesQuery());

  const updateCategoryCaptionMutation = ({ type, caption }: { type?: string; caption?: string }) =>
    api
      .put(`${apiRoutes.reports}/${reportId}/files/section`, {
        type: type,
        caption: caption ?? null,
      })
      .then((res) => res);

  const { mutateAsync: updateCategoryCaptionMutate, isLoading: updateCategoryCaptionLoading } = useMutation(
    'updateCategoryCaptionMutation',
    (value: { type?: string; caption?: string }) => updateCategoryCaptionMutation(value),
    {
      onSuccess: () => {
        toast.success('Category caption has been successfully updated!');
        getReportTypes();
        setExpandedSubTitle(null);
      },
    },
  );

  const editFileMutation = (value: string, id: number | string) =>
    api.put(`${apiRoutes.reports}/${reportId}/files/${id}`, { caption: value }).then((res) => res);

  const { mutateAsync: editFileMutate, isLoading: editFileLoading } = useMutation(
    'editWorkOrderMutation',
    ({ id, value }: { id: number | string; value: string }) => editFileMutation(value, id),
    {
      onSuccess: () => {
        toast.success('File has been successfully edited!');
        getReportFiles();
        setIsNewImage(false);
      },
    },
  );

  useEffect(() => {
    switch (reportType) {
      case ReportTypesEnum.Inspection:
        return setActiveTab('photo');
      case ReportTypesEnum.PreConstruction:
        return setActiveTab('exhibit_a');
    }
  }, [reportTypes]);

  const handleOpenSubTitleEdit = (id?: string) => {
    expandedSubTitle === id ? setExpandedSubTitle(null) : setExpandedSubTitle(id);
  };

  const filteredTypes = useMemo(() => (reportTypes?.length ? reportTypes : []), [reportTypes]);

  function a11yProps(index: string) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: string) => {
    setActiveTab(newValue);
  };

  function TabPanel(props: { value: string; index?: number; children?: React.ReactNode }) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== activeTab}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === activeTab && <>{children}</>}
      </div>
    );
  }

  const handlePhotoUpload = function (e: React.ChangeEvent) {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    handleFilesUploadAndShowProgress(files);
  };

  const handleFilesUploadAndShowProgress = async (files: FileList | null) => {
    const dragDropArea = document.querySelector('.drawer-drag-drop-area') as HTMLElement;

    if (files) {
      const fileToArray: File[] = Object.values(files);

      try {
        for (let i = 0; i < fileToArray.length; i++) {
          const formData = new FormData();
          formData.append('file', fileToArray[i]);
          await api.post(`${apiRoutes.reports}/${reportId}/files/upload`, formData, {
            onUploadProgress: ({ loaded, total }) => {
              const fileLoaded = Math.floor((loaded / total) * 100);
              const progressHTML = `<div>
                                      <p className="name">${fileLoaded}% ${i} files from ${fileToArray.length} uploaded...</p>
                                    </div>`;
              dragDropArea.innerHTML = progressHTML;
              dragDropArea.classList.add(styles.dragDropArea);
            },
          });
        }
        getReportFiles();
        dragDropArea.innerHTML = '';
        dragDropArea.classList.remove(styles.dragDropArea);
        toast.success('File has been successfully saved!');
      } catch (e) {
        toast.error('Something go wrong, please try again');
        dragDropArea.innerHTML = '';
        dragDropArea.classList.remove(styles.dragDropArea);
      }
    }
  };

  const selectedFiles = useMemo(() => {
    return reportFiles?.filter(({ type: typeId }) => typeId === activeTab) ?? [];
  }, [activeTab, reportFiles]);

  useEffect(() => {
    setExpandedSubTitle(null);
  }, [activeTab]);

  const saveCaption = async ({ id, value }: { id: number | string; value: string }) => {
    if (openFilePreview) {
      if (selectedFiles?.length) {
        let fileIndex = selectedFiles?.findIndex(({ id: idx }) => id === idx);
        if (fileIndex + 1 !== selectedFiles.length) {
          startFile.current = ++fileIndex;
          setIsNewImage(true);
          editFileMutate({ id, value });
          // setTimeout(() => {
          // editFileMutate({ id, value });
          // }, 2000);
          return;
        }
        startFile.current = fileIndex;
      }
    }
    setIsNewImage(true);
    // setTimeout(() => {
    editFileMutate({ id, value });
    // }, 2000);
  };

  if (isLoading || reportTypesLoading || updateCategoryCaptionLoading) return <CircularProgress size={64} />;

  return (
    <div className="pt-16">
      <span className={clsx('text-primary weight-600 py-8 px-16', styles.donwloadContainer)}>
        <label htmlFor="file-drawer-upload" className="c-pointer flex align-items-center">
          <DownloadIcon className={styles.downloadIcon} />
          <span>Add Photos</span>
        </label>
        <input
          hidden
          type="file"
          id="file-drawer-upload"
          accept="image/png, image/jpeg"
          className="file-input"
          multiple
          onChange={handlePhotoUpload}
        />
      </span>
      <div className="pt-16" style={{ marginLeft: '-16px', marginRight: '-16px' }}>
        <Tabs value={activeTab} onChange={handleChange} scrollButtons="on" aria-label="simple tabs example">
          {filteredTypes?.map(({ type, title }) => (
            <Tab
              key={type}
              icon={<EditIcon className={styles.labelEditIcon} onClick={() => handleOpenSubTitleEdit(type)} />}
              label={title}
              value={type}
              {...a11yProps(type as string)}
            />
          ))}
        </Tabs>

        {filteredTypes?.map(({ type, caption }) => (
          <div key={type} className="p-relative">
            {expandedSubTitle === type && (
              <AccordionItem
                originalCaption={caption}
                type={type}
                updateCategoryCaption={updateCategoryCaptionMutate}
              />
            )}
          </div>
        ))}

        {filteredTypes?.map(({ type }, index) => (
          <TabPanel key={index} value={type as string}>
            <DragDropFiles
              files={selectedFiles}
              reportId={reportId}
              getReportFiles={getReportFiles}
              filteredTypes={filteredTypes}
              openFilePreview={openFilePreview}
              setOpenFilePreview={setOpenFilePreview}
              startFile={startFile}
              editFileMutate={editFileMutate}
              editFileLoading={editFileLoading}
            />
          </TabPanel>
        ))}
      </div>
      <CloseIcon className={styles.closePhotoDrawerIcon} onClick={handleClose} />
      <Dialog fullWidth maxWidth={'sm'} open={openFilePreview} onClose={() => setOpenFilePreview(false)}>
        <DialogTitle>
          <CloseIcon className={styles.closeIcon} onClick={() => setOpenFilePreview(false)} />
        </DialogTitle>
        <FilePreview
          documents={selectedFiles}
          initialActiveDocument={startFile}
          className={styles.docViewer}
          updateFile={saveCaption}
          editFileLoading={editFileLoading}
          isNewImage={isNewImage}
        />
      </Dialog>
      <div className={'drawer-drag-drop-area'}></div>
    </div>
  );
};

export default PhotoDrawer;
