import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import clsx from 'clsx';

import { PhotoViewer } from '../PhotoViewer';
import styles from './PhotoViewerDialog.module.scss';

import { components } from 'generated/types';
type InspectionPhoto = components['schemas']['InspectionPhoto'];

interface PhotoViewerDialogProps {
  openViewer: boolean;
  handleCloseViewer: () => void;
  children?: ReactNode;
  slides: InspectionPhoto[];
  onDelete: (id: number) => void;
  onCaptionChange: (id: number, value: string) => void;
  onToggleReport: (id: number) => void;
  currentIndex: number;
  onRotateImage: (id: number) => void;
}

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: '1160px',
    maxHeight: '768px',
    margin: 'auto',
    borderRadius: '12px',
    backgroundColor: '#fff',
    padding: '0',
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.5)',
    position: 'relative',
    height: 'calc(100vh - 172px)',
    overflow: 'visible',
    top: '-7vh',
  },
  dialogBackdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
}));

export const PhotoViewerDialog: React.FC<PhotoViewerDialogProps> = ({
  openViewer,
  handleCloseViewer,
  slides,
  onDelete,
  onCaptionChange,
  onToggleReport,
  currentIndex,
  onRotateImage,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={openViewer}
      onClose={handleCloseViewer}
      classes={{ paper: classes.dialogPaper }}
      BackdropProps={{
        classes: { root: classes.dialogBackdrop },
      }}
    >
      <div className={clsx(styles.mainContainer)}>
        <PhotoViewer
          slides={slides}
          onDelete={onDelete}
          onCaptionChange={onCaptionChange}
          onToggleReport={onToggleReport}
          currentIndex={currentIndex}
          onRotateImage={onRotateImage}
          onClose={handleCloseViewer}
        />
      </div>
    </Dialog>
  );
};
