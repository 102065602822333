import { IconProps } from 'types';

export const CheckedIcon = ({ className, onClick, height = 18, width = 18 }: IconProps) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="9" fill="#009933" />
      <path
        d="M7.63637 12.2727C7.50001 12.2727 7.36365 12.2181 7.25456 12.1091L4.52728 9.38178C4.3091 9.1636 4.3091 8.83632 4.52728 8.61814C4.74547 8.39996 5.07274 8.39996 5.29092 8.61814L7.63637 10.9636L12.7091 5.89087C12.9273 5.67269 13.2546 5.67269 13.4727 5.89087C13.6909 6.10905 13.6909 6.43632 13.4727 6.65451L8.01819 12.1091C7.9091 12.2181 7.77274 12.2727 7.63637 12.2727Z"
        fill="white"
      />
    </svg>
  );
};
